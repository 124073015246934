import { useSelector } from "react-redux";
import { FC, ComponentProps } from "react";
import * as _ from "lodash";

import {
  ChartFilterType,
  DIMGroupList,
  Enterprise,
  EnterpriseAppState,
  HerdGroupEntity,
  HerdGroupList,
  PenEntity,
  PensList,
  DIMGroupEntity
} from "@ctra/api";

import { Analytics, FilterOutlined, FilterFilled, CheckboxMenu } from "@ctra/components";
import { Enterprise as Content, useTranslation } from "@ctra/i18n";

import { DIMGroupFilter, HerdGroupFilter, PenFilter, useChartAPI, useChartFilters } from "@chart";
import { GACategories } from "@analytics";

const {
  V3: { MultiDropdown }
} = Analytics;

const {
  chart: { series: seriesAsset }
} = Content;

/**
 * Filter controller for variant
 * @param {string | undefined} type
 * @returns {JSX.Element | null}
 * @constructor
 */
export const Filter: FC<{ type?: string }> = ({ type }) => {
  const { t } = useTranslation();
  const { series, filters, api, metadata } = useChartFilters();

  const {
    chart: {
      flags: { isSeriesTranslatable }
    }
  } = useChartAPI();

  const { herdGroupID, dimGroupKey, penName } = filters;
  const { setFilter, setSeries } = api;
  const { showFilters, supportedfarmIDs } = metadata;

  /**
   * Handle herd group changes
   * @param herdGroupIDList
   */
  const onHerdGroupChange: ComponentProps<typeof HerdGroupFilter>["onHerdGroupChange"] = (herdGroupIDList) =>
    setFilter(ChartFilterType.herdGroup, herdGroupIDList);

  /**
   * Handle pen changes
   * @param {Array<PenEntity["id"]>} penNameList
   */
  const onPenChange: ComponentProps<typeof PenFilter>["onPenChange"] = (
    penNameList: Array<PenEntity["id"]>
  ) => setFilter(ChartFilterType.penName, penNameList);

  /**
   * Handle DIM group changes
   * @param {Array<DIMGroupEntity["id"]>} dimGroupIDList
   */
  const onDIMGroupChange: ComponentProps<typeof DIMGroupFilter>["onDIMGroupChange"] = (
    dimGroupIDList: Array<DIMGroupEntity["id"]>
  ) => setFilter(ChartFilterType.dimGroup, dimGroupIDList);

  const dimGroups = useSelector<EnterpriseAppState, DIMGroupList>((state) =>
    Enterprise.entities.getDIMGroups(state, { farmID: supportedfarmIDs })
  );

  /**
   * Fetch the herd groups which are available for the given farms
   */
  const herdGroups = useSelector<EnterpriseAppState, HerdGroupList>((state) =>
    Enterprise.entities.getHerdGroups(state, { farmID: supportedfarmIDs })
  );

  /**
   * Pick the pens which are available for the given farms
   * @type {PensList}
   */
  const pens = useSelector<EnterpriseAppState, PensList>((state) =>
    Enterprise.entities.getPens(state, { farmID: supportedfarmIDs })
  );

  /**
   * Combine the two lists
   * @type {{[p: string]: PenEntity, [p: number]: HerdGroupEntity}}
   */
  const input: Record<HerdGroupEntity["id"] | PenEntity["id"], HerdGroupEntity | PenEntity> = {
    ...pens,
    ...herdGroups
  };

  return showFilters ? (
    <>
      {!!dimGroupKey && (
        <MultiDropdown
          icon={FilterOutlined}
          values={_.map(_.sortBy(dimGroups, "sortableName"), "id")}
          selection={dimGroupKey}
          onChange={onDIMGroupChange}
          placeholder={"no filters"}
        />
      )}
      {!!herdGroupID && (
        <MultiDropdown<HerdGroupEntity["id"]>
          values={_.map(
            _.sortBy(herdGroups, (group) => {
              /**
               * some of the group names are numeric strings, we pad them so, they can be ordered lexicographically
               */
              return _.isInteger(_.parseInt(group.name)) ? _.padStart(group.name, 4) : group.name;
            }),
            "id"
          )}
          selection={herdGroupID}
          onChange={onHerdGroupChange}
          placeholder={"no filters"}
        />
      )}
      {!!penName && (
        <MultiDropdown<PenEntity["id"]>
          values={_.uniq(
            _.map(
              _.sortBy(input, (entity) => parseFloat(entity.name)),
              "name"
            )
          )}
          selection={penName}
          onChange={onPenChange}
          placeholder={"no filters"}
        />
      )}
      {/** Show the checkbox even if there is only one option available */}
      <CheckboxMenu
        icon={_.size(series.active) > 0 ? <FilterFilled /> : <FilterOutlined />}
        size="large"
        data-gtm-category={GACategories.configurator}
        data-gtm-action="Change filters"
        data-gtm-info={JSON.stringify({ values: series.active })}
        onChange={(value) =>
          setSeries({
            available: series.available,
            active: value as string[]
          })
        }
        items={_.map(series.available, (label, value) => ({
          label: isSeriesTranslatable ? t<string>(seriesAsset(value)) : label,
          value
        }))}
        value={_.values(series.active)}
      >
        Filter by {_.startCase(type)}
      </CheckboxMenu>
    </>
  ) : null;
};
