import * as _ from "lodash";
import moment from "moment";
import { ComponentProps, FC, Fragment, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Markdown from "react-markdown";

import {
  Typography,
  ImpactTracking,
  Col,
  Row,
  Skeleton,
  Alert,
  Space,
  Tag,
  Button,
  PlusOutlined,
  Spin,
  Switch,
  Popover
} from "@ctra/components";

import {
  DataAvailability,
  Direction,
  ImpactTracking as ImpactTrackingAPI,
  Result,
  DataDescriptorEntity
} from "@ctra/api";

import { useTranslation } from "@ctra/i18n";
import { Enterprise as Content } from "@ctra/i18n";
import { Nullable, UnitSystem } from "@ctra/utils";
import { useGoogleAnalytics } from "@ctra/analytics";

import { useLocalization, DataDictionaryContext } from "@base";
import { ImpactTrackingContext, useEvent, GAActions, GACategory } from "@events";
import { ChartPicker } from "@analytics";
import { useFarm } from "@farms";

import { OverlapEvent } from "../OverlapEvent";
import styles from "./ImpactTrackedEventPage.module.less";

const { Title, Paragraph, Text } = Typography;
const { KPIChange, DateRangePicker, Variant, WidgetWrapper } = ImpactTracking;

type ValueVariant = "before" | "after" | "projected";

/**
 * Get the direction based on the before and after values
 * @param {number} before
 * @param {Nullable<number>} after
 * @returns {Direction.increase}
 */
const getDirection = (before: Nullable<number>, after: Nullable<number>) => {
  const hasBefore = _.isNumber(before);
  const hasAfter = _.isNumber(after);

  if (hasAfter && hasBefore) {
    if (after - before > 0) {
      return Direction.increase;
    } else if (after - before === 0) {
      return Direction.stagnate;
    } else {
      return Direction.decrease;
    }
  }

  if (hasBefore && !hasAfter) {
    return Direction.decrease;
  }

  if (!hasBefore && hasAfter) {
    return Direction.increase;
  }

  return Direction.stagnate;
};

/**
 * Round the value based on the data descriptor
 * @param {Nullable<number>} value
 * @param {DataDescriptorEntity} dataDescriptor
 * @returns {number | null}
 */
const round = (value: Nullable<number>, dataDescriptor: DataDescriptorEntity) => {
  return _.isNumber(value) ? _.round(value, _.get(dataDescriptor, ["valueProperties", "decimals"], 2)) : null;
};

const {
  events: {
    page: { title, description }
  },
  unitSystem: { unit },
  impactTracking: {
    projectionsWarning,
    description: valueDescription,
    showProjections,
    timePeriod,
    noData,
    whatElse,
    beta,
    add
  },
  kpi,
  askIda: {
    answer: {
      change: { after, before, expected }
    },
    createTracker: {
      configure: {
        labels: { change },
        placeholders: { kpi: kpiCol }
      }
    },
    noData: { description: unsupportedIntervalDescription }
  }
} = Content;

/**
 * Impact tracked event page component
 * @returns {JSX.Element}
 * @constructor
 */
export const ImpactTrackedEventPage: FC = () => {
  const { t } = useTranslation();
  const { unitSystem } = useLocalization();
  const [currentTimeSpan, setCurrentTimeSpan] = useState("P3D");
  const [newMetric, setNewMetric] = useState("");
  const [includeProjections, setIncludeProjections] = useState(false);
  const dispatch = useDispatch();
  const { farm } = useFarm();
  const isKPIProcessing = useRef(false);
  const { trackEvent } = useGoogleAnalytics();

  const {
    event: {
      id,
      source: { type },
      startAt
    }
  } = useEvent();

  /**
   * Durations for date range
   * @type {string[]}
   */
  const currentDurations = ["P3D", "P1W", "P2W", "P4W"];

  /**
   * Get the value/label for the given result set
   * @param {Result} input
   * @param {"before" | "after"} variant
   * @param {React.ComponentProps<React.FunctionComponent<Omit<KPIChangeProps, "label">>>["before"]["value"]} value
   * @returns {React.ComponentProps<React.FunctionComponent<Omit<KPIChangeProps, "label">>>["before"]}
   */
  const getValueProps = (
    input: Result,
    variant: ValueVariant,
    value: NonNullable<ComponentProps<typeof KPIChange>[ValueVariant]>["value"]
  ): NonNullable<ComponentProps<typeof KPIChange>[ValueVariant]> => {
    /**
     * If the data is missing, find out why
     */
    const dataMissingReason = _.get(input, "dataUnavailabilityReason", null);

    const labelMap: Record<ValueVariant, string> = {
      before: before,
      after: after,
      projected: expected
    };

    return {
      value,
      noData: t<string>(noData.label, {
        verbose: false,
        dataStatus: input.dataAvailability,
        reason: dataMissingReason
      }),
      label: t<string>(labelMap[variant])
    };
  };

  /**
   * Make a label for missing data
   * @returns {any}
   */
  const getMissingDataLabel = (input?: Result) => {
    const isTracking = moment(startAt)
      .add(moment.duration(currentTimeSpan).asDays(), "days")
      .isAfter(moment(), "day");

    const { dataAvailability, dataUnavailabilityReason } = _.defaultTo(input, {
      dataAvailability: null,
      dataUnavailabilityReason: null
    });

    const reason = _.defaultTo(dataUnavailabilityReason, isTracking ? "tracking" : "empty");

    return t<string>(noData.label, {
      verbose: true,
      dataStatus: dataAvailability,
      reason
    });
  };

  return (
    <DataDictionaryContext.Provider>
      <DataDictionaryContext.Consumer>
        {({
          dataDescriptors: dataDictionary,
          subsets: { projectionsEnabled },
          meta: { isLoading: isDataDictionaryLoading }
        }) => (
          <ImpactTrackingContext.Provider annotationID={id} timeSpan={currentTimeSpan}>
            <ImpactTrackingContext.Consumer>
              {({
                results,
                otherResults,
                meta: {
                  isLoading: isImpactTrackingResultLoading,
                  isRejected,
                  isProcessing,
                  isAdding,
                  isIntervalSupported
                }
              }) => {
                /**
                 * Results with projected values
                 * @type {Dictionary<{imperial: Result, si: Result}>}
                 */
                const withPojectedValues = _.pickBy(
                  results,
                  (value) => !_.isNil(_.get(value, [unitSystem, "projected"]))
                );

                /**
                 * Tell whether projections are available at all
                 * @type {boolean}
                 */
                const hasProjections = !!_.size(_.intersection(projectionsEnabled, _.keys(results)));

                /**
                 * Tell if there are any projected values in the data
                 * @type {boolean}
                 */
                const hasProjectedValues = !!_.size(_.keys(withPojectedValues));

                const hasProjectionWarning =
                  (_.size(projectionsEnabled) > 0 && !hasProjections) ||
                  !_.size(projectionsEnabled) ||
                  !hasProjectedValues;

                const PopoverComponent = hasProjectionWarning ? Popover : Fragment;

                return (
                  <section className={styles.PageWrapper}>
                    <Skeleton
                      active
                      loading={isDataDictionaryLoading || (isImpactTrackingResultLoading && !isProcessing)}
                    >
                      <Title level={6} theme="black">
                        <Space>
                          <span>{t<string>(title, { type })}</span>
                          {/* @todo make a component and remove when not needed anymore */}
                          <Tag
                            style={{
                              backgroundColor: "#666",
                              color: "#80FFEC",
                              border: "none",
                              position: "relative",
                              top: "-3px"
                            }}
                          >
                            {t<string>(beta)}
                          </Tag>
                        </Space>
                      </Title>
                      <Paragraph>{t<string>(description, { type })}</Paragraph>
                      <Row style={{ marginBottom: "16px" }} justify="end">
                        <Col span={24}>
                          <Space>
                            <Text type={hasProjections && hasProjectedValues ? void 0 : "secondary"}>
                              {t<string>(showProjections)}
                            </Text>
                            <PopoverComponent
                              content={
                                <Markdown>
                                  {t<string>(projectionsWarning, {
                                    farmName: farm?.name,
                                    reason:
                                      _.size(projectionsEnabled) > 0 && !hasProjections
                                        ? "noMetricSupport"
                                        : !_.size(projectionsEnabled)
                                        ? "noFarmSupport"
                                        : "noData"
                                  })}
                                </Markdown>
                              }
                            >
                              <Switch
                                data-gtm-category={GACategory.eventPage}
                                data-gtm-action="Toggle predictions"
                                disabled={!hasProjections || !hasProjectedValues}
                                checked={includeProjections}
                                onChange={(checked) => {
                                  setIncludeProjections(checked);
                                }}
                              />
                            </PopoverComponent>
                          </Space>
                        </Col>
                      </Row>
                      <DateRangePicker
                        className={styles.RangePicker}
                        durations={_.map(currentDurations, (duration) => ({
                          duration,
                          label: t(timePeriod(duration))
                        }))}
                        value={currentTimeSpan}
                        center={moment(startAt)}
                        labels={{ before: t(before), after: t(after) }}
                        onChange={(value) => {
                          trackEvent(GAActions.pickTimePeriod, { label: value });
                          setCurrentTimeSpan(value);
                        }}
                      />
                      <OverlapEvent duration={currentTimeSpan} />
                      {isRejected ? (
                        <Alert
                          message={getMissingDataLabel()}
                          description={t(unsupportedIntervalDescription)}
                        />
                      ) : _.isEmpty(results) ? (
                        <Alert
                          message={t(noData.message, { isIntervalSupported })}
                          description={t(noData.description, { isIntervalSupported })}
                        />
                      ) : (
                        <>
                          <Row gutter={[16, 16]}>
                            {_.map(results, (result, dataDescriptorID) => {
                              const currentChange = result[unitSystem];
                              const dataDescriptor = _.get(dataDictionary, [dataDescriptorID]);
                              const beforeValue = round(currentChange.before, dataDescriptor);
                              const afterValue = round(currentChange?.after, dataDescriptor);
                              const projectedValue = round(currentChange?.projected, dataDescriptor);

                              /**
                               * Get direction based on current change
                               * @type {Direction.increase | Direction.stagnate | Direction.decrease}
                               */
                              const direction = getDirection(beforeValue, afterValue);

                              isKPIProcessing.current = _.isEqual(
                                _.get(currentChange, "dataAvailability"),
                                DataAvailability.processing
                              );

                              /**
                               * Get the units to use
                               * @type {undefined}
                               */
                              const units = _.get(dataDescriptor, ["valueProperties", "units"]);

                              return (
                                <Col md={12} span={24} key={dataDescriptorID}>
                                  <KPIChange
                                    loading={!dataDescriptor || isKPIProcessing.current}
                                    labels={{
                                      kpi: dataDescriptor
                                        ? t(
                                            kpi.displayName(
                                              _.get(dataDescriptor, ["dataProperties", "typeName"])
                                            ),
                                            { variant: null, makeDefaultValue: true }
                                          )
                                        : "...",
                                      direction: t(change(direction)),
                                      noData: getMissingDataLabel(currentChange)
                                    }}
                                    before={getValueProps(
                                      currentChange,
                                      "before",
                                      _.isNull(currentChange?.before) ? null : beforeValue
                                    )}
                                    after={getValueProps(
                                      currentChange,
                                      "after",
                                      _.isNull(currentChange?.after) ? null : afterValue
                                    )}
                                    projected={
                                      includeProjections && !_.isNull(projectedValue)
                                        ? {
                                            ...getValueProps(currentChange, "projected", projectedValue),
                                            description: t<string>(valueDescription.projected)
                                          }
                                        : void 0
                                    }
                                    direction={direction}
                                    suffix={
                                      dataDescriptor
                                        ? t(unit(_.get(units, unitSystem, _.get(units, UnitSystem.metric))), {
                                            onAssetError: ""
                                          })
                                        : "..."
                                    }
                                    context={{
                                      dataStatus: _.defaultTo(currentChange?.dataAvailability, null),
                                      missingDataReason: _.defaultTo(
                                        currentChange?.dataUnavailabilityReason,
                                        null
                                      )
                                    }}
                                  />
                                </Col>
                              );
                            })}
                            {(isProcessing || isAdding) && !isKPIProcessing.current && (
                              <Col md={12} span={24}>
                                <Spin spinning={isAdding} tip={t(add.alert.loading)}>
                                  <Alert
                                    message={t(add.alert.message)}
                                    description={t(add.alert.description)}
                                    type="info"
                                  />
                                </Spin>
                              </Col>
                            )}
                            <Col md={12} span={24}>
                              <WidgetWrapper label={t(add.title)}>
                                <Row gutter={[12, 0]} wrap={false}>
                                  <Col flex={1}>
                                    {/** @todo replace with metric picker when analytics v3 is done */}
                                    <ChartPicker
                                      placement="impactTracking"
                                      showButton={false}
                                      showLabel={false}
                                      onChange={setNewMetric}
                                      filterValues={_.keys(results)}
                                      filter={({ flags: { isImpactTrackingSupported } }) =>
                                        isImpactTrackingSupported
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <Button
                                      disabled={!newMetric}
                                      type="secondary"
                                      icon={<PlusOutlined />}
                                      onClick={() => {
                                        trackEvent(GAActions.addNewMetric, { label: newMetric });

                                        dispatch(
                                          ImpactTrackingAPI.actions.addMetricToEvent.start(id, newMetric)
                                        );
                                      }}
                                    >
                                      {t(add.cta)}
                                    </Button>
                                  </Col>
                                </Row>
                              </WidgetWrapper>
                            </Col>
                          </Row>
                          {!_.isEmpty(otherResults) && (
                            <>
                              <Title className={styles.Title} level={6} theme="black">
                                {t(whatElse.title)}
                              </Title>
                              <Paragraph>{t(whatElse.description)}</Paragraph>
                              <WidgetWrapper
                                className={styles.Table}
                                label={
                                  <Row wrap={false}>
                                    <Col span={6}>
                                      <Text size="md">{t(kpiCol)}</Text>
                                    </Col>
                                    <Col span={6}>
                                      <Text size="md">{t(before)}</Text>
                                    </Col>
                                    <Col span={6}>
                                      <Text size="md">{t(after)}</Text>
                                    </Col>
                                    <Col span={6}>
                                      <Text size="md">{`${t(change(Direction.increase))} / ${t(
                                        change(Direction.decrease)
                                      )}`}</Text>
                                    </Col>
                                  </Row>
                                }
                              >
                                {_.map(otherResults, (result, dataDescriptorID) => {
                                  /**
                                   * Get current change value based on current unit system
                                   */
                                  const currentChange = result[unitSystem];

                                  /**
                                   * Get data descriptor from data dictionary
                                   * @type {DataDescriptorEntity}
                                   */
                                  const dataDescriptor = dataDictionary[dataDescriptorID];

                                  /**
                                   * Get before and after values
                                   * @type {number}
                                   */
                                  const beforeValue = _.round(
                                    currentChange?.before,
                                    _.get(dataDescriptor, ["valueProperties", "decimals"], 2)
                                  );

                                  const afterValue = _.isNumber(currentChange?.after)
                                    ? _.round(
                                        currentChange.after,
                                        _.get(dataDescriptor, ["valueProperties", "decimals"], 2)
                                      )
                                    : null;

                                  /**
                                   * Get direction based on current change
                                   * @type {Direction.increase | Direction.stagnate | Direction.decrease}
                                   */
                                  const direction = getDirection(beforeValue, afterValue);
                                  const units = _.get(dataDescriptor, ["valueProperties", "units"]);

                                  return (
                                    <KPIChange
                                      key={dataDescriptorID}
                                      className={styles.TableEntry}
                                      variant={Variant.table}
                                      labels={{
                                        kpi: dataDescriptor
                                          ? t(
                                              kpi.displayName(
                                                _.get(dataDescriptor, ["dataProperties", "typeName"])
                                              ),
                                              { variant: null, makeDefaultValue: true }
                                            )
                                          : "...",
                                        direction: t(change(direction))
                                      }}
                                      before={{
                                        value: beforeValue
                                      }}
                                      after={{
                                        value: afterValue
                                      }}
                                      direction={direction}
                                      suffix={
                                        dataDescriptor
                                          ? t(
                                              unit(_.get(units, unitSystem, _.get(units, UnitSystem.metric))),
                                              {
                                                onAssetError: ""
                                              }
                                            )
                                          : "..."
                                      }
                                    />
                                  );
                                })}
                              </WidgetWrapper>
                            </>
                          )}
                        </>
                      )}
                    </Skeleton>
                  </section>
                );
              }}
            </ImpactTrackingContext.Consumer>
          </ImpactTrackingContext.Provider>
        )}
      </DataDictionaryContext.Consumer>
    </DataDictionaryContext.Provider>
  );
};
